import React from "react"
import SmoothScrollbar from "../components/scrollbar"
import { Link } from "gatsby"
import Img from "gatsby-image"

import SEO from "../components/seo"

import "./project.scss"

const ProjectLayout = ({ pageContext: { project } }) => {
  return (
    <>
      <SEO title={project.node.title} />
      <SmoothScrollbar>
        <main className="project-page-wrapper">
          <section className="project-page-inner">
            <div className="project-page-hero-wrapper">
              <div className="project-page-hero-inner">
                <h2>{project.node.title}</h2>
                <div className="project-page-hero-text">
                  <h3>Work:</h3>
                  <ul>
                    {project.node.tags.map((tag, index) => (
                      <li key={index}>{tag}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="project-page-cover-wrapper">
              <Img
                fluid={project.node.coverImage.fluid}
                className="project-page-cover"
                draggable={false}
              />
            </div>
            <div className="project-page-description-wrapper">
              <h3>Project Info:</h3>
              <p>{project.node.description.description}</p>
            </div>
            <div className="project-page-images-wrapper">
              {project.node.images.map((image, index) => (
                <Img
                  fluid={image.fluid}
                  key={index}
                  className="project-page-image"
                  draggable={false}
                />
              ))}
            </div>
            <div className="project-page-adjacents-wrapper">
              <div className="project-page-previous-wrapper">
                {project.previous !== null ? (
                  <Link
                    to={`/${project.previous.slug}`}
                    className="project-page-previous-link"
                  >
                    ← Previous Project
                  </Link>
                ) : null}
              </div>
              <div className="project-page-next-wrapper">
                {project.next !== null ? (
                  <Link
                    to={`/${project.next.slug}`}
                    className="project-page-next-link"
                  >
                    Next Project →
                  </Link>
                ) : null}
              </div>
            </div>
          </section>
        </main>
      </SmoothScrollbar>
    </>
  )
}

export default ProjectLayout
